<template>
	<div class="stagIngBox">
		<div class="topDiv">
			<div class="title">油脂消耗统计</div>
			<div class="showBox row-me row-center">
				<div class="div div1">
					<div class="row-me row-center margin-top30 margin-left30">
						<img src="../../assets/img/icon_ljxh.png" class="iconImg" />
						<div class="txt">累计消耗（ML）</div>
					</div>
					<div class="txtNum">{{form.all_grease_value}}</div>
				</div>
				<div class="div div2">
					<div class="row-me row-center margin-top30 margin-left30">
						<img src="../../assets/img/icon_byxh.png" class="iconImg" />
						<div class="txt">本月消耗（ML）</div>
					</div>
					<div class="txtNum" style="color: #33C77C;">{{form.month_grease_value}}</div>
				</div>
				<div class="div div3">
					<div class="row-me row-center margin-top30 margin-left30">
						<img src="../../assets/img/icon_bnxh.png" class="iconImg" />
						<div class="txt">本年消耗（ML）</div>
					</div>
					<div class="txtNum" style="color: #6956F0;">{{form.year_grease_value}}</div>
				</div>
			</div>
		</div>
		<div class="mainBox row-me row-center">
			<div class="flex1 cenBox margin-right20 posRelative">
				<div class="font-size24 font-bold padding-top30 padding-left30 posAbsolute" style="color: #252630;z-index: 99999;">设备状态统计</div>
				<div ref="chart" class="chartDiv01"></div>
				<div class="row-me row-center column-center">
					<div class="row-me row-center">
						<div class="bg1"></div>
						<div class="font-size14" style="color: #252630;">正常</div>
					</div>
					<div class="row-me row-center bg2Box">
						<div class="bg2"></div>
						<div class="font-size14" style="color: #252630;">异常</div>
					</div>
					<div class="row-me row-center">
						<div class="bg3"></div>
						<div class="font-size14" style="color: #252630;">离线</div>
					</div>
				</div>
			</div>
			<div class="flex1 cenBox posRelative">
				<div class="font-size24 font-bold padding-top30 padding-left30 posAbsolute" style="color: #252630;">设备可用率</div>
				<div ref="chart1" class="chartDiv01"></div>
				<div class="row-me row-center column-center">
					<div class="row-me row-center">
						<div class="font-size14" style="color: #252630;">可用设备：{{num1}}</div>
					</div>
					<div class="row-me row-center bg2Box" style="margin-right: 0;">
						<div class="font-size14" style="color: #252630;">设备总数：{{num2}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {},
				dataList: [],
				num1: '',
				num2: '',
			}
		},
		created() {

		},
		mounted() {

			// var echartData = [{
			// 	value: 1,
			// 	name: '正常'
			// }, {
			// 	value: 2,
			// 	name: '异常'
			// }, {
			// 	value: 3,
			// 	name: '离线'
			// }]
			this.$post("/index/workbenchData", {}).then((res) => {
				if (res) {
					const myChart = this.$echarts.init(this.$refs.chart);
					var scale = 1;
					this.form = res.data.greaseData;
					var echartData = res.data.deviceState;
					console.log(echartData, 'echartDataechartData')

					var rich = {
						yellow: {
							color: "#000",
							fontSize: 30 * scale,
							padding: [5, 4],
							align: 'center'
						},
						total: {
							color: "#000",
							fontSize: 40 * scale,
							align: 'center'
						},
						white: {
							color: "#000",
							align: 'center',
							fontSize: 14 * scale,
							padding: [21, 0]
						},
						blue: {
							color: '#000',
							fontSize: 16 * scale,
							align: 'center'
						},
						hr: {
							borderColor: '#000',
							width: '100%',
							borderWidth: 1,
							height: 0,
						}
					}
					myChart.setOption({
						backgroundColor: '#fff',
						title: {
							text: '设备总数',
							left: 'center',
							top: '53%',
							padding: [10, 0],
							textStyle: {
								color: '#000',
								fontSize: 18 * scale,
								align: 'center'
							}
						},
						legend: {
							selectedMode: false,
							formatter: function(name) {
								var total = 0; //各科正确率总和
								var averagePercent; //综合正确率
								echartData.forEach(function(value, index, array) {
									total += value.value;
								});
								return '{total|' + total + '}';
							},
							data: [echartData[0].name],
							// data: ['高等教育学'],
							// itemGap: 50,
							left: 'center',
							top: 'center',
							icon: 'none',
							align: 'center',
							textStyle: {
								color: "#000",
								fontSize: 16 * scale,
								rich: rich
							},
						},
						series: [{
							name: '设备总数',
							type: 'pie',
							radius: ['42%', '50%'],
							hoverAnimation: false,
							color: ["#3D6EE2", "#EB4B4D", "#D6D9E2"],
							label: {
								normal: {
									formatter: function(params, ticket, callback) {
										var total = 0; //设备总数量
										var percent = 0; //设备占比
										echartData.forEach(function(value, index, array) {
											total += value.value;
										});
										percent = ((params.value / total) * 100).toFixed(1);
										return '{white|' + params.name + '}\n{hr|}\n{yellow|' +
											params.value +
											'}\n{blue|' + percent + '%}';
									},
									rich: rich
								},
							},
							// labelLine: {
							// 	normal: {
							// 		length: 55 * scale,
							// 		length2: 0,
							// 		lineStyle: {
							// 			color: '#fff'
							// 		}
							// 	}
							// },
							data: echartData
						}]
					})
				}
			}).catch((error) => {
				this.$message.error(error.message)
			})

			this.$post("/index/workbenchData", {}).then((res) => {
				if (res) {
					const myChart = this.$echarts.init(this.$refs.chart1);
					var scale = 1;
					this.dataList = res.data.deviceAvaila;
					this.num1 = res.data.deviceAvaila[0].value;
					this.num2 = res.data.deviceAvaila[1].value;
					var echartData = res.data.deviceAvaila;
					var rich = {
						yellow: {
							color: "#000",
							fontSize: 30 * scale,
							padding: [5, 4],
							align: 'center'
						},
						total: {
							color: "#000",
							fontSize: 40 * scale,
							align: 'center'
						},
						white: {
							color: "#000",
							align: 'center',
							fontSize: 14 * scale,
							padding: [21, 0]
						},
						blue: {
							color: '#000',
							fontSize: 16 * scale,
							align: 'center'
						},
						hr: {
							borderColor: '#000',
							width: '100%',
							borderWidth: 1,
							height: 0,
						}
					}
					myChart.setOption({
						backgroundColor: '#fff',
						title: {
							text: '设备总数',
							left: 'center',
							top: '53%',
							padding: [10, 0],
							textStyle: {
								color: '#000',
								fontSize: 18 * scale,
								align: 'center'
							}
						},
						legend: {
							selectedMode: false,
							formatter: function(name) {
								var total = 0; //各科正确率总和
								var averagePercent; //综合正确率
								echartData.forEach(function(value, index, array) {
									total += value.value;
								});
								return '{total|' + total + '}';
							},
							data: [echartData[0].name],
							// data: ['高等教育学'],
							// itemGap: 50,
							left: 'center',
							top: 'center',
							icon: 'none',
							align: 'center',
							textStyle: {
								color: "#000",
								fontSize: 16 * scale,
								rich: rich
							},
						},
						series: [{
							name: '设备总数',
							type: 'pie',
							radius: ['42%', '50%'],
							hoverAnimation: false,
							color: ["#3D6EE2", "#D6D9E2"],
							label: {
								normal: {
									formatter: function(params, ticket, callback) {
										var total = 0; //设备总数量
										var percent = 0; //设备占比
										echartData.forEach(function(value, index, array) {
											total += value.value;
										});
										percent = ((params.value / total) * 100).toFixed(1);
										return '{white|' + params.name + '}\n{hr|}\n{yellow|' +
											params.value +
											'}\n{blue|' + percent + '%}';
									},
									rich: rich
								},
							},
							data: echartData
						}]
					})
				}
			}).catch((error) => {
				this.$message.error(error.message)
			})




		},
		methods: {

		}
	}
</script>


<style lang="scss" scoped>
	// 开始
	.stagIngBox {
		width: 95%;
		// height: 100%;
		height: calc(100vh - 140px);
		margin: 0.4rem auto;
		// background: #5F6770;

		.topDiv {
			width: 100%;
			height: 263px;
			background: #FFFFFF;
			box-shadow: 0px 0px 20px rgba(17, 130, 251, 0.05);
			margin-bottom: 20px;

			.title {
				font-size: 24px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #252630;
				padding: 30px;
			}

			.showBox {
				.div {
					// width: 500px;
					// width: 33%;
					height: 142px;
					flex: 1;

					.iconImg {
						width: 26px;
						height: 26px;
						margin-right: 20px;
					}

					.txt {
						font-size: 20px;
						color: #5F6770;
					}

					.txtNum {
						font-size: 34px;
						font-family: DIN;
						font-weight: bold;
						color: #1182FB;
						margin-left: 76px;
						margin-top: 14px;
					}
				}

				.div1 {
					background-image: url("../../assets/img/bg_ljxh.png");
					background-size: 100% 100%;
					margin-left: 30px;
				}

				.div2 {
					background-image: url("../../assets/img/bg_byxh.png");
					background-size: 100% 100%;
					margin-left: 20px;
					margin-right: 20px;
				}

				.div3 {
					background-image: url("../../assets/img/bg_bnxh.png");
					background-size: 100% 100%;
					margin-right: 30px;
				}
			}
		}

		.mainBox {
			width: 100%;
			height: calc(100vh - 424px);
			// background: indianred;

			.cenBox {
				width: 100%;
				height: 100%;
				background: #fff;

				.chartDiv01 {
					margin: 0 auto;
					width: 440px;
					height: 440px;
					margin-top: 30px;
				}

				.bg1 {
					width: 16px;
					height: 16px;
					background: #3D6EE2;
					margin-right: 10px;
				}

				.bg2Box {
					margin-left: 60px;
					margin-right: 60px;
				}

				.bg2 {
					width: 16px;
					height: 16px;
					background: #EB4B4D;
					margin-right: 10px;
				}

				.bg3 {
					width: 16px;
					height: 16px;
					background: #D6D9E2;
					margin-right: 10px;
				}
			}
		}
	}

	// 结束
</style>